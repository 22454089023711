/* Hide scrollbar for Chrome, Safari and Opera */
.channels-scroll-container::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .channels-scroll-container {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    overflow-x: hidden;
    overflow-y: scroll;
    margin-top: 25px;
    margin-bottom: 15px;
    border: 0px solid white;
    border-radius: 15px;
    padding: 10px;
    min-height: 200px;
    max-height: 490px;
  }

  .channels-scroll-inner-container-mobile {
    width: 100%;
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
    gap: 15px;
  }

  .channels-scroll-inner-container-web {
    width: 90%;
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
    gap: 15px;
  }

  /* channel type drop-down  */
  .channel-typ-dropdown {
    width: 90%;
    background-color: transparent;
    color: white;
    font-size: 15px;
    border: 1px solid white;
    border-radius: 10px;
  }

  .horizontal-row {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  /* Channel icon container inside the cards mobile responsive*/
  .channel-icon-container-sml {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* Channel icon container inside the cards web responsive*/
  .channel-icon-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* Image icon inside the card mobile responsive*/
  .img-channel-icon-sml {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  /* Image icon inside the card web responsive*/
  .img-channel-icon {
    height: 100;
    width: 100;
    object-fit: contain;
  }

  /* Add channel rate column in cards */
  .channel-tbl-rate-clm {
    /* background-color: transparent; */
    background-color: #eb631c;
    color: white;
    text-align: center;
    border: 0px solid white;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }

  .channel-add-button {
    padding: 10px; 
    border: 0px solid white; 
    border-radius: 15px;  
    background-color: green;
    color: white;
    cursor: pointer;
  }

  /* .channel-add-button:hover {
    padding: 5px;
    font-size: 16px;
    background-color: darkgreen;
  } */

  .channel-remove-button {
    padding: 10px; 
    border: 0px solid white; 
    border-radius: 15px;  
    background-color: red;
    color: white;
    cursor: pointer;
  }

  /* .channel-remove-button:hover {
    padding: 5px; 
    font-size: 16px;
    background-color: darkred;
  } */

  /* Add channel checkout button */
  .channel-ckut-btn {
    width: 90%;
    padding: 10px;
    border: 1px solid white;
    border-radius: 15px;
    background-color: #eb631c;
    color: white;
  }

  /*------------------------------ Style related to complaints module ---------------------------------*/

  .bodyMainConatiner {
    margin-top: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .complaintsMainConatiner {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  .complaintsInputEle {
    width: 90%;
    height: 45px;
    border: 0px solid black;
    border-radius: 10px;
    text-align: center;
  }

  .complaintsTextareaInput {
    width: 90%;
    border: 0px solid black;
    border-radius: 10px;
    text-align: center;
  }

  .complaintsTextareaInput::placeholder {
    padding-top: 10px;
  }

  .tab-content {
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .tab-content-mobile {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .addComplaintsButton {
    width: 90%;
    height: 45px;
    border: 1px solid white;
    border-radius: 10px;
    background-color: #eb631c;
    color: white;
  }

  .tabsButtonContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 15px;
  }

  .tabButton {
    padding: 10px;
    border: 1px solid white;
    border-radius: 10px;
    background-color: transparent;
    color: white;
    cursor: pointer;
  }

  .active {
    padding: 10px;
    border: 2px solid white;
    border-radius: 10px;
    background-color: #eb631c;
    color: white;
    cursor: pointer;
  }